

















































































import Vue from "vue";
import { mapState } from "vuex";
import { dispatch, ListQuery } from "@/store";
import { download, xlsx } from "@/utils";
import moment from "moment";

export default Vue.extend({
  data() {
    return {
      loading: false,
      form: this.$form.createForm(this),
      list: [],
      query: {
        ordering: "id",
        page_number: 1,
        page_size: 10
      },
      count: 0
    };
  },
  computed: mapState({
    columns() {
      return [
        {
          title: "用户 ID",
          dataIndex: "user_id"
        },
        {
          title: "昵称",
          dataIndex: "nickname"
        },
        {
          title: "下单平台",
          dataIndex: "platform"
        },
        {
          title: "订单号",
          dataIndex: "order_no"
        },
        {
          title: "邮箱",
          dataIndex: "email"
        },
        {
          title: "左 RGB 灯",
          dataIndex: "custom_info.left"
        },
        {
          title: "右 RGB 灯",
          dataIndex: "custom_info.right"
        },
        {
          title: "充电口上方字符",
          dataIndex: "custom_info.top"
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          scopedSlots: { customRender: "time" }
        },
        {
          title: "更新时间",
          dataIndex: "update_time",
          scopedSlots: { customRender: "time" }
        }
      ];
    }
  }),
  methods: {
    getList(query: ListQuery) {
      this.loading = true;
      dispatch.settingsGetKeyboardCustomList(query).then(res => {
        this.loading = false;
        this.list = res.results;
        this.query = {
          ...query,
          page_number: res.page_number,
          page_size: res.page_size
        };
        this.count = res.count;
        this.loading = false;
      });
    },
    onFilterChange() {
      const filter = this.form.getFieldsValue();
      this.getList({
        ...this.query,
        ...filter,
        page_number: 1
      });
    },
    onFilterReset() {
      this.form.resetFields();
      this.onFilterChange();
    },
    onTableChange(page: any, filters: any, sorter: any) {
      let ordering =
        sorter.order === "ascend" ? "-" + (sorter.columnKey || "") : "";
      ordering = sorter.order === "descend" ? sorter.columnKey || "" : ordering;
      this.getList({
        ...this.query,
        page_number: page.current,
        page_size: page.pageSize,
        ordering: ordering || this.query.ordering
      });
    },
    onExport() {
      this.$message.info("定制信息导出中…");
      this.loading = true;
      let list: any[] = [];
      const getList = (pageNumber: number) =>
        dispatch
          .settingsGetKeyboardCustomList({
            ...this.query,
            page_number: pageNumber,
            page_size: 1000
          })
          .then(res => {
            list = [...list, ...res.results];
            if (res.count > list.length) {
              getList(pageNumber + 1);
            } else {
              const data = [
                [
                  "用户 ID",
                  "昵称",
                  "下单平台",
                  "订单号",
                  "邮箱",
                  "左 RGB 灯",
                  "右 RGB 灯",
                  "充电口上方字符",
                  "创建时间",
                  "更新时间"
                ]
              ];
              list.map(item => {
                data.push([
                  item.user_id,
                  item.nickname,
                  item.platform,
                  item.order_no,
                  item.email,
                  item.custom_info.left,
                  item.custom_info.right,
                  item.custom_info.top,
                  moment(item.update_time).format("YYYY-MM-DD HH:mm:ss"),
                  moment(item.create_time).format("YYYY-MM-DD HH:mm:ss")
                ]);
              });
              const file = xlsx([
                {
                  name: "定制信息列表",
                  data,
                  cols: [
                    { wch: 10 },
                    { wch: 20 },
                    { wch: 10 },
                    { wch: 40 },
                    { wch: 30 },
                    { wch: 10 },
                    { wch: 10 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 }
                  ]
                }
              ]);
              this.$message.success("定制信息导出成功，正在下载…");
              download(file, "65 Less 定制列表.xlsx");
              this.loading = false;
            }
          })
          .catch(() => {
            this.loading = false;
            this.$message.error("定制信息导出失败");
          });
      getList(1);
    }
  },
  mounted() {
    this.getList(this.query);
  }
});
